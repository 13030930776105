body {
    margin: 0;
    padding: 0;
    background: black;
    font-family: "IBM Plex Mono", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:link,
a:hover,
a:visited {
    text-decoration: underline;
    color: white;
    font-style: italic;
    text-underline-offset: 4px;
}

#globekit-container {
    z-index: 1000;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    position: relative;
}

#globekit-canvas {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#wrapper {
    width: 600px;
    margin: 20px auto;
}

path {
    fill: #aaa;
    stroke: #ddd;
    stroke-width: 0.5;
}

#globeViz {
    height: 450px;
    width: 450px;
}

canvas {
    width: 100vw !important;
}

@keyframes moveLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-25vw);
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(-25vw);
    }
    100% {
        transform: translateX(0);
    }
}

.move-left {
    animation: moveLeft 0.4s ease-out forwards;
}
.move-right {
    animation: moveRight 0.4s ease-out forwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fadein linear 1s;
    -webkit-animation: fadein linear 1s;
    -moz-animation: fadein linear 1s;
    -o-animation: fadein linear 1s;
    -ms-animation: fadein linear 1s;
}

.video-react {
    background-color: transparent !important;
}

.video-react-video {
    border-radius: 8px !important;
}

@media (min-width: 800px) {
    .video-react-video {
        border-radius: 0px !important;
    }
}
